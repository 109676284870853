<!--
  -- Created by zed on 2022/3/1
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 数据总览
-->
<template>
  <div class='dashboard__wrap'>
    <!-- 用户分析 -->
    <!-- <el-row :gutter='32'>
      <el-col :lg='12'>
        <div class='chart-wrapper'>
          <div class='chart-title'>
            用户性别分析
            <div class='chart-subtitle'>用户注册量：{{ userTotal }}</div>
          </div>
          <pie-chart
            ref='userSexPieChart'
            width='100%'
            height='400px'
            tooltip='用户性别'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col>
      <el-col :lg='12'>
        <div class="chart-wrapper">
          <div class='chart-title'>
            用户年龄范围分析
            <div class='chart-subtitle'>用户注册量：{{ userTotal }}</div>
          </div>
          <pie-chart
            ref='userAgePieChart'
            width='100%'
            height='400px'
            tooltip='年龄范围'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row> -->
    <!-- 设备 -->
    <el-row justify="center">
      <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>设备比例分析</div>
          <pie-chart
            ref='devicePieChart'
            width='100%'
            height='auto'
            tooltip='设备数量'
            :labelnormalShow="false"
          />
        </div>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>设备数据统计</div>
          <bar-chart
            ref='deviceBarChart'
            direction='horizontal'
            width='100%'
            height='auto'
            tooltip='设备数量'
          />
        </div>
      </el-col>
    </el-row>
    <!-- 提醒事项 -->
    <el-row justify="center">
      <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>提醒事项比例分析</div>
          <pie-chart
            ref='remindPieChart'
            width='100%'
            height='400px'
            tooltip='提醒事项'
            @onPieceClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col>
        <div class='chart-wrapper'>
          <div class='chart-title'>提醒事项数据统计</div>
          <bar-chart
            ref='remindBarChart'
            direction='vertical'
            width='100%'
            height='400px'
            tooltip='提醒事项'
            @onAxisClick='onPetVarietyClick'
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BarChart from './components/BarChart';
import PieChart from './components/PieChart';

export default {
  name: 'Dashboard',
  components: { BarChart, PieChart },
  data() {
    return {
      userTotal: 0,
      devicePieChartHeight: 0,
    }
  },
  mounted() {
    this.setupUser()
    this.setupDevice()
    this.setupRemind()
  },
  methods: {
    async setupUser() {
      let resp = await this.$moe_api.STATISTICS_API.readUser()
      if (resp.code === 200) {
        this.userTotal = resp.result.total
        let sexDataSources = resp.result.sexGroup.map(item => {
          return {
            id: null,
            name: item.name,
            value: item.value,
          }
        })
        let ageDataSources = resp.result.ageRangeGroup.map(item => {
          return {
            id: null,
            name: item.name,
            value: item.value,
          }
        })
        this.$refs.userSexPieChart.configChart(sexDataSources)
        this.$refs.userAgePieChart.configChart(ageDataSources)
      }
    },
    async setupDevice() {
      let resp = await this.$moe_api.STATISTICS_API.readDevice()
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            name: item.typeName,
            value: item.total
          }
        })
        this.$refs.deviceBarChart.configChart(dataSources)
        this.devicePieChartHeight = this.$refs.deviceBarChart.getChartSize().height
        this.$refs.devicePieChart.configChart(dataSources)
      }
    },
    async setupRemind() {
      let resp = await this.$moe_api.STATISTICS_API.readRemind()
      if (resp.code === 200) {
        let dataSources = resp.result.map(item => {
          return {
            name: item.remindName,
            value: item.total
          }
        })
        this.$refs.remindPieChart.configChart(dataSources)
        this.$refs.remindBarChart.configChart(dataSources)
      }
    },
    onPetVarietyClick() {}
  }
}
</script>

<style lang='scss' scoped>
.dashboard__wrap {
  padding:18px 16px;
}
.chart-title {
  text-align: center;
  margin-bottom: 16px;
}
.chart-subtitle {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 2px;
}
.chart-wrapper {
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 16px;
  margin-bottom: 32px;
}
</style>
